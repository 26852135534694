import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { TectonicAsset, TectonicSDK } from '@cronos-labs/tectonic-sdk';
import { BigNumber, utils } from 'ethers';

import { TONIC_DECIMALS } from '@config/constants';
import { getBoostXTonicApyRate } from '@lib/math';
import { QueryKey } from '@config/queryKey';
import { PoolType } from '@config/base';
import { getQuery } from '@queries/queries';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';
import useWallets from '@hooks/useWallets';
import useGetXTonicBoostDailyDistributeRate, {
  BoostType,
} from '@hooks/useGetXTonicBoostDailyDistributeRate';
import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';
import useTonicUsdPrice from '@hooks/useTonicUsdPrice';
import useAssetTokenUsdPrice from '@hooks/useAssetTokenUsdPrice';
import useTTokenExchangeRate from '@hooks/useTTokenExchangeRate';

import { getBoostedAssetDetails } from './utils';

const useGetBoostingMultiplier = () => {
  const sdk = useTectonicSdk();
  const { currentAccount } = useWallets();

  const query = getQuery(QueryKey.CALCULATE_BOOST_MULTIPLIER)(
    sdk,
    currentAccount ?? ''
  );

  return useQuery(query.queryKey, query.queryFn);
};

export interface UseGetUserBoostCollectionsOptions {
  skip?: boolean;
}
const useGetUserBoostCollections = (
  boostType: BoostType,
  options?: UseGetUserBoostCollectionsOptions
) => {
  const sdk = useTectonicSdk();
  const { currentAccount } = useWallets();
  const query = getQuery(QueryKey.GET_USER_BOOST_COLLECTIONS)(
    sdk,
    currentAccount ?? '',
    boostType
  );

  return useQuery(query.queryKey, query.queryFn, {
    enabled: !!currentAccount && !options?.skip,
  });
};

const useRemoveBoostCollections = (
  tTokens: string[],
  boostType: BoostType[]
) => {
  const sdk = useTectonicSdk();
  const query = getQuery(QueryKey.REMOVE_BOOST_MARKET)(sdk, tTokens, boostType);

  return useQuery(query.queryKey, query.queryFn);
};

const useXTonicMarketStates = (tTokens: string, boostType: BoostType) => {
  const sdk = useTectonicSdk();
  const query = getQuery(QueryKey.GET_TONIC_MARKETSTATES)(
    sdk,
    tTokens,
    boostType
  );

  return useQuery(query.queryKey, query.queryFn);
};

const useGetTTokenAmountUser = (
  sdk: TectonicSDK,
  currentAccount: Null<string>,
  boostType: BoostType,
  tTokenAddress: string
) => {
  const tTokenAmountQuery = getQuery(
    boostType === BoostType.SUPPLY
      ? QueryKey.GET_TTOKEN_SUPPLY_AMOUNT_USER
      : QueryKey.GET_TTOKEN_BORROW_AMOUNT_USER
  )(sdk, currentAccount ?? '', tTokenAddress);
  return useQuery(tTokenAmountQuery.queryKey, tTokenAmountQuery.queryFn, {
    enabled: !!currentAccount,
  });
};
interface useGetAssetDetailsResult {
  boostedAsset: TectonicAsset;
  pool: PoolType;
}
const useGetAssetDetails = (
  tTokenAddress?: TectonicAsset['tTokenAddress']
): useGetAssetDetailsResult | undefined => {
  const { list: supportedAssetsMainPool } = useSdkAndSupportedAssets('MAIN');
  const { list: supportedAssetsVenoPool } = useSdkAndSupportedAssets('VENO');
  const { list: supportedAssetsDefiPool } = useSdkAndSupportedAssets('DEFI');

  return useMemo(() => {
    if (!tTokenAddress) {
      return;
    }
    const boostedMainAsset = getBoostedAssetDetails(
      supportedAssetsMainPool,
      tTokenAddress
    );
    if (boostedMainAsset) {
      return { boostedAsset: boostedMainAsset, pool: 'MAIN' };
    }
    const boostedVenoAsset = getBoostedAssetDetails(
      supportedAssetsVenoPool,
      tTokenAddress
    );
    if (boostedVenoAsset) {
      return { boostedAsset: boostedVenoAsset, pool: 'VENO' };
    }
    const boostedDefiAsset = getBoostedAssetDetails(
      supportedAssetsDefiPool,
      tTokenAddress
    );
    if (boostedDefiAsset) {
      return { boostedAsset: boostedDefiAsset, pool: 'DEFI' };
    }
  }, [
    supportedAssetsMainPool,
    supportedAssetsVenoPool,
    supportedAssetsDefiPool,
    tTokenAddress,
  ]);
};

const useXTonicBoostAPY = (
  tTokenAddress: TectonicAsset['tTokenAddress'],
  boostType: BoostType,
  pool?: PoolType
) => {
  const { currentAccount } = useWallets();
  const sdk = useTectonicSdk(pool);
  const assetDetails = useGetAssetDetails(tTokenAddress);
  const { data: marketStates } = useXTonicMarketStates(
    tTokenAddress,
    boostType
  );
  const { data: multiplier } = useGetBoostingMultiplier();
  const { data: tTokenAmount } = useGetTTokenAmountUser(
    sdk,
    currentAccount,
    boostType,
    tTokenAddress
  );

  const { data: tonicDailyDistributeRate } =
    useGetXTonicBoostDailyDistributeRate(tTokenAddress, boostType);
  const { tonicUsdPrice } = useTonicUsdPrice();
  const { data: assetUsdPrice } = useAssetTokenUsdPrice(
    assetDetails?.pool,
    tTokenAddress
  );
  const cnMultiplier = multiplier
    ? utils.parseUnits(multiplier.toString(), TONIC_DECIMALS)
    : BigNumber.from('0');

  const { data: supplyAssetExchangeRate } = useTTokenExchangeRate(
    tTokenAddress,
    sdk
  );
  return useMemo(() => {
    const adjustedAmount =
      tTokenAmount &&
      tTokenAmount.mul(cnMultiplier).div(BigNumber.from(10).pow(18));

    const XTonicBoostApy =
      adjustedAmount &&
      assetDetails?.boostedAsset &&
      assetUsdPrice &&
      tonicDailyDistributeRate &&
      tonicUsdPrice &&
      supplyAssetExchangeRate &&
      marketStates?.[2]
        ? getBoostXTonicApyRate(
            adjustedAmount,
            marketStates?.[2].add(adjustedAmount),
            tonicDailyDistributeRate,
            tonicUsdPrice,
            assetDetails.boostedAsset,
            boostType === BoostType.BORROW
              ? tTokenAmount
              : supplyAssetExchangeRate.mul(tTokenAmount),
            assetUsdPrice
          )
        : 0;
    return XTonicBoostApy;
  }, [
    marketStates?.[2],
    tonicDailyDistributeRate,
    tonicUsdPrice,
    assetDetails?.boostedAsset,
    supplyAssetExchangeRate,
    assetUsdPrice,
    tTokenAmount,
    cnMultiplier,
    tTokenAddress,
  ]);
};

const useGetUserInfo = (tTokenAddress: string, boostType: BoostType) => {
  const sdk = useTectonicSdk();
  const { currentAccount } = useWallets();
  const query = getQuery(QueryKey.GET_USER_BOOST_INFO)(
    sdk,
    currentAccount ?? '',
    tTokenAddress,
    boostType
  );
  return useQuery(query.queryKey, query.queryFn, {
    enabled: !!currentAccount,
  });
};

const useXTonicBoostedAPY = (
  tTokenAddress: TectonicAsset['tTokenAddress'],
  boostType: BoostType,
  pool?: PoolType
) => {
  const sdk = useTectonicSdk(pool);
  const assetDetails = useGetAssetDetails(tTokenAddress);
  const { data: userInfo } = useGetUserInfo(tTokenAddress, boostType);
  const { data: marketStates } = useXTonicMarketStates(
    tTokenAddress,
    boostType
  );
  const { data: tonicDailyDistributeRate } =
    useGetXTonicBoostDailyDistributeRate(tTokenAddress, boostType);
  const { tonicUsdPrice } = useTonicUsdPrice();
  const { data: assetUsdPrice } = useAssetTokenUsdPrice(
    assetDetails?.pool,
    tTokenAddress
  );

  const { data: supplyAssetExchangeRate } = useTTokenExchangeRate(
    tTokenAddress,
    sdk
  );

  return useMemo(() => {
    const XTonicBoostedApy =
      userInfo &&
      assetDetails?.boostedAsset &&
      assetUsdPrice &&
      tonicDailyDistributeRate &&
      tonicUsdPrice &&
      supplyAssetExchangeRate &&
      marketStates?.[2]
        ? getBoostXTonicApyRate(
            userInfo?.[3],
            marketStates?.[2],
            tonicDailyDistributeRate,
            tonicUsdPrice,
            assetDetails.boostedAsset,
            boostType === BoostType.BORROW
              ? userInfo?.[2]
              : supplyAssetExchangeRate.mul(userInfo?.[2]),
            assetUsdPrice
          )
        : 0;
    return XTonicBoostedApy;
  }, [
    userInfo,
    marketStates?.[2],
    tonicDailyDistributeRate,
    tonicUsdPrice,
    assetDetails?.boostedAsset,
    supplyAssetExchangeRate,
    assetUsdPrice,
    tTokenAddress,
  ]);
};

export {
  useGetBoostingMultiplier,
  useGetUserBoostCollections,
  useGetAssetDetails,
  useRemoveBoostCollections,
  useXTonicBoostAPY,
  useXTonicBoostedAPY,
};
