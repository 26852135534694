import React from 'react';
import Image from 'next/image';
import clsx from 'clsx';

import { NFTRarity } from '../useNFTMeta';

interface Props {
  src: string;
  onClick?: VoidFunction;
  nftRarity?: NFTRarity;
  className?: string;
  imageWidth?: number;
  imageHeigh?: number;
  rarityClassName?: string;
}

export default function StakedImage({
  src,
  nftRarity,
  className,
  rarityClassName,
  imageWidth,
  imageHeigh,
  onClick,
}: Props) {
  return (
    <div
      className={clsx(
        'relative h-[120px] w-[120px] cursor-pointer rounded-[4px] border-1 border-vaultChart6Months ',
        className
      )}
      onClick={onClick}
    >
      <Image
        className="rounded-[4px]"
        src={src}
        alt="nft image"
        width={imageWidth || 120}
        height={imageHeigh || 120}
      />

      <div
        className={clsx(
          'absolute top-1 left-1 w-fit rounded-[5px] bg-white60 p-[1px] text-center text-small font-medium',
          {
            'bg-gradient-to-r from-yellowBorderActive to-borderGradientTo':
              nftRarity === 'Legendary',
          }
        )}
      >
        <div
          className={clsx(
            'w-fit rounded-[5px] bg-blueElevatedSurface py-[1px] px-[3px]',
            rarityClassName
          )}
        >
          {nftRarity}
        </div>
      </div>
    </div>
  );
}
