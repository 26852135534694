import { Modal, ModalProps } from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';

import { formatTime } from '@lib/dates';
import useIsMobile from '@hooks/useIsMobile';

import { StakedImage } from '../index';
import { NFT } from '../useNFTMeta';

interface Props extends Pick<ModalProps, 'isOpen' | 'onClose'> {
  data: Null<NFT>;
  nftMultiplierData: Undefined<number>;
  stakedSince: Undefined<string>;
}

export default function NFTDetailModal({
  isOpen,
  onClose,
  data,
  nftMultiplierData,
  stakedSince,
}: Props) {
  const isMobile = useIsMobile();

  return (
    <Modal
      className={clsx('h-[666px] w-[510px]', {
        'fixed bottom-0 right-0 top-0 left-0 !h-[100vh] !max-h-screen !w-[100vw]':
          isMobile,
      })}
      isShowMobileSliderClose={isMobile}
      title="NFT Details"
      variant="wallet"
      isOpen={isOpen}
      onClose={onClose}
    >
      <StakedImage
        src={data?.image ?? ''}
        nftRarity={data?.rarity}
        className="m-auto !h-[310px] !w-[343px] mobile:border-0"
        rarityClassName="text-large !p-1"
        imageWidth={343}
        imageHeigh={310}
      />

      <div className="mb-2 mt-3 flex w-full flex-row justify-between text-mediumSmall font-normal">
        <div className="flex flex-row items-center justify-center">NFT</div>

        <span className="font-semibold">{data?.name}</span>
      </div>
      <div className="mb-2 flex w-full flex-row justify-between text-mediumSmall font-normal">
        <div className="flex flex-row items-center justify-center">
          NFT Boost Multiplier
        </div>

        <span className="font-semibold text-green">{nftMultiplierData}x</span>
      </div>
      <div className="mb-2 flex w-full flex-row justify-between text-mediumSmall font-normal">
        <div className="flex flex-row items-center justify-center">
          Staked since
        </div>

        <span className="font-semibold">
          {stakedSince && formatTime(Number(stakedSince) * 1000)}
        </span>
      </div>
    </Modal>
  );
}
