import 'styles/globals.css';
import 'nprogress/nprogress.css';
import '@lib/h';
import Head from 'next/head';
import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import NProgress from 'nprogress';
import { ethers } from 'ethers';
import { Web3ReactProvider } from '@web3-react/core';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';

import AppEffects from '@components/AppEffects';
import AppLayout from '@components/AppLayout';
import Seo from '@components/Seo';
import SupportedAssetsProvider from '@providers/SupportedAssetsProvider';
import TectonicSdkProvider from '@providers/TectonicSdkProvider';
import WalletProvider from '@providers/WalletProvider';
import wrapper from '@redux/wrapper';
import { start as startBugsnag } from '@lib/bugsnag';
import { getGtmHeadScriptContent, trackPageView } from '@lib/gtm';
import MobileNavigationDrawerProvider from '@providers/MobileNavigationDrawerProvider';
import EnsProvider from '@providers/EnsProvider';
import AppLayoutLanding from '@components/AppLayout/AppLayoutLanding';
import CronosUpgradeWarning from '@components/CronosUpgradeWarning';
import { NoSSR } from '@components/NoSSR';

const gtmHeadScriptContent = getGtmHeadScriptContent();

const ErrorBoundary = startBugsnag();

function getWeb3Library(
  provider:
    | ethers.providers.ExternalProvider
    | ethers.providers.JsonRpcFetchFunc
): ethers.providers.Web3Provider {
  return new ethers.providers.Web3Provider(provider);
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, q) => console.error('error at query', q.queryKey),
  }),
  defaultOptions: {
    queries: {
      retry: (failCount) => failCount < 1,
      refetchOnWindowFocus: false,
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const isLandingPage = useMemo(
    () => router.pathname === '/',
    [router.pathname]
  );

  useEffect(() => {
    const onRouteChange = (url: string): void => trackPageView(url);
    const startNProgress = () => NProgress.start();
    const endNProgress = () => NProgress.done();

    router.events.on('routeChangeStart', startNProgress);
    router.events.on('routeChangeComplete', endNProgress);
    router.events.on('routeChangeError', endNProgress);
    router.events.on('routeChangeComplete', onRouteChange);

    return () => {
      router.events.off('routeChangeStart', startNProgress);
      router.events.off('routeChangeComplete', endNProgress);
      router.events.off('routeChangeError', endNProgress);
      router.events.off('routeChangeComplete', onRouteChange);
    };
  }, [router.events]);

  return (
    <>
      {gtmHeadScriptContent && (
        <>
          {/* Google Tag Manager - Global base code */}
          <Script
            id="google-tag-manager"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: gtmHeadScriptContent,
            }}
          />
        </>
      )}
      <ErrorBoundary>
        {!isLandingPage ? (
          <Web3ReactProvider getLibrary={getWeb3Library}>
            <EnsProvider>
              <QueryClientProvider client={queryClient}>
                <WalletProvider>
                  <TectonicSdkProvider poolType="MAIN">
                    <TectonicSdkProvider poolType="DEFI">
                      <TectonicSdkProvider poolType="VENO">
                        <SupportedAssetsProvider>
                          <AppEffects />
                          <MobileNavigationDrawerProvider>
                            <AppLayout>
                              <Seo
                                title="Tectonic | Cross-chain Money Market | Earn High Yield And Borrow Loans On Cronos"
                                description="Tectonic is the first lending and borrowing platform of the Cronos ecosystem, powered by the TONIC governance token. Tectonic is your gateway to earn passive yield and access instance backed loans"
                              />
                              <Head>
                                <meta
                                  name="viewport"
                                  content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
                                />
                              </Head>
                              <CronosUpgradeWarning>
                                <Component {...pageProps} />
                              </CronosUpgradeWarning>
                            </AppLayout>
                          </MobileNavigationDrawerProvider>
                        </SupportedAssetsProvider>
                      </TectonicSdkProvider>
                    </TectonicSdkProvider>
                  </TectonicSdkProvider>
                </WalletProvider>
              </QueryClientProvider>
            </EnsProvider>
          </Web3ReactProvider>
        ) : (
          <NoSSR>
            <AppLayoutLanding>
              <QueryClientProvider client={queryClient}>
                <TectonicSdkProvider poolType="MAIN">
                  <TectonicSdkProvider poolType="VENO">
                    <TectonicSdkProvider poolType="DEFI">
                      <SupportedAssetsProvider>
                        <Component {...pageProps} />
                      </SupportedAssetsProvider>
                    </TectonicSdkProvider>
                  </TectonicSdkProvider>
                </TectonicSdkProvider>
              </QueryClientProvider>
            </AppLayoutLanding>
          </NoSSR>
        )}
      </ErrorBoundary>
    </>
  );
}
export default wrapper.withRedux(MyApp);
