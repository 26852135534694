import { ReactNode } from 'react';
import {
  Icon,
  MobileNavigationDrawer,
  Text,
} from '@tectonicfi/tectonic-ui-kit';
import Link from 'next/link';

import Features from '@components/Features';

import WalletInfo from './WalletInfo';
import CommunityLists from './CommunityLists';
import { PATHNAME, getNavBarLabel } from './utils';

interface MobileNavigationDrawerItemProps {
  children: ReactNode;
}

function MobileNavigationDrawerItem({
  children,
}: MobileNavigationDrawerItemProps): JSX.Element {
  return <li className="pb-4">{children}</li>;
}

interface AppMobileDrawerProps {
  onClose(): void;
  open: boolean;
}

function AppMobileDrawer({ onClose, open }: AppMobileDrawerProps): JSX.Element {
  return (
    <MobileNavigationDrawer
      // Should match height of NavBar
      className="top-[74px] h-[calc(100vh-74px)] desktop:hidden tablet:hidden"
      open={open}
    >
      <MobileNavigationDrawer.Content className="h-fit min-h-full p-8">
        <ul>
          <MobileNavigationDrawerItem>
            <WalletInfo />
          </MobileNavigationDrawerItem>

          <MobileNavigationDrawerItem>
            <Link href={PATHNAME.markets} legacyBehavior>
              <a onClick={onClose}>
                <Text as="span" variant="heading3">
                  {getNavBarLabel(PATHNAME.markets)}
                </Text>
              </a>
            </Link>
          </MobileNavigationDrawerItem>
          <MobileNavigationDrawerItem>
            <Link href={PATHNAME.dashboard} legacyBehavior>
              <a onClick={onClose}>
                <Text as="span" variant="heading3">
                  {getNavBarLabel(PATHNAME.dashboard)}
                </Text>
              </a>
            </Link>
          </MobileNavigationDrawerItem>
          <Features.Earn>
            <MobileNavigationDrawerItem>
              <Link href={PATHNAME.tonic} legacyBehavior>
                <a onClick={onClose}>
                  <Text as="span" variant="heading3">
                    {getNavBarLabel(PATHNAME.tonic)}
                  </Text>
                </a>
              </Link>
            </MobileNavigationDrawerItem>
          </Features.Earn>
          <Features.Analytics>
            <MobileNavigationDrawerItem>
              <Link href={PATHNAME.analytics} legacyBehavior>
                <a onClick={onClose}>
                  <Text as="span" variant="heading3">
                    {getNavBarLabel(PATHNAME.analytics)}
                  </Text>
                </a>
              </Link>
            </MobileNavigationDrawerItem>
          </Features.Analytics>

          <MobileNavigationDrawerItem>
            <CommunityLists />
          </MobileNavigationDrawerItem>
          <MobileNavigationDrawerItem>
            <div className="border-bottom-1 border-b-1 border-greyBackground pb-3">
              <a
                href="https://tectonic.gitbook.io/docs/introduction/what-is-tectonic"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Text className="text-vaultChart6Months">Whitepaper</Text>
              </a>
            </div>
          </MobileNavigationDrawerItem>
          <MobileNavigationDrawerItem>
            <Icon.PoweredByCronos className="h-4 w-[114px]" />
          </MobileNavigationDrawerItem>
        </ul>
      </MobileNavigationDrawer.Content>
    </MobileNavigationDrawer>
  );
}

export default AppMobileDrawer;
