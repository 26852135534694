import { gql } from 'graphql-request';

export interface MarketInfoResponse {
  timestamp: string;
  borrowRate: number;
  supplyRate: number;
  totalBorrowUsd: number;
  totalSupplyUsd: number;
  id: string;
}

export interface MarketsQueryData {
  borrowApyPercent: number;
  supplyApyPercent: number;
  timestamp: Date;
  borrowRate: number;
  supplyRate: number;
  totalBorrowUsd: number;
  totalSupplyUsd: number;
  id: string;
}

export interface AccountResponse {
  account: {
    stakedAmount: number | null;
  };
}

export interface StakeEventsResponse {
  stakeEvents: StakeEventData[];
}

export interface ReleaseEventsResponse {
  releaseEvents: ReleaseEventData[];
}

export interface StakeEventData {
  account: {
    id: string;
  };
  blockNumber: number;
  tonicStaked: string;
  xTonicMinted: string;
}

export interface ReleaseEventData {
  account: {
    id: string;
  };
  blockNumber: number;
  xTonicBurned: string;
  tonicReleased: string;
}

export interface XtonicStatsResponse {
  xtonicStats: {
    xTonicPriceInUsd: string;
    xTonicTotalSupply: string;
    xTonicMarketCap: string;
    xTonicExchangeRate: string;
    stakedTonic: string;
    avgLockTime: string;
    apr7d: string;
  };
}

export interface XtonicAprsResponse {
  xtonicAprsPerDays: XtonicAprsPerDayData[];
}

interface XtonicAprsPerDayData {
  id: string;
  date: number;
  apr7d: string;
}

export interface TransactionEventsResponse {
  transactionEvents: TransactionEventData[];
}

export interface TonicAssistInfosResponse {
  tonicAssistInfos: TonicAssistInfosData[];
}

export interface TransactionEventData {
  id: string;
  date: number;
  type: string;
  tokenAmount: string;
  tokenSymbol: string;
  valueInUsd: string;
  address: string;
}

export interface TransactionEventCountResponse {
  transactionEventCount: {
    allCount: number;
    supplyCount: number;
    redeemCount: number;
    borrowCount: number;
    repayCount: number;
    liquidationCount: number;
  };
}

export interface TonicAssistInfosData {
  market: string;
  totalBorrowerUniqueAddressAmount: string;
  totalSupplierUniqueAddressAmount: string;
}
export interface FinancialsAddressAmountsResult {
  financialStatementsPerDays: {
    id: string;
    date: number;
    totalSupplierUniqueAddressAmount: string;
    totalBorrowerUniqueAddressAmount: string;
    market: string;
  }[];
}
export interface NumFinancialStatementsResult {
  financialStatementsPerDays: {
    id: string;
  }[];
}

export interface FinancialStatementsPerDayResult {
  financialStatementsPerDays: {
    id: string;
    market: string;
    date: number;
    sumOfSuppliesInUsd: string;
    sumOfRedeemsInUsd: string;
    sumOfBorrowsInUsd: string;
    sumOfRepaysInUsd: string;
    sumOfLiquidationsInUsd: string;
    totalSupplyInUsd: string;
    totalBorrowInUsd: string;
    sumOfAccruedInterestInUsd: string;
    totalUtilization: string;
    protocolRevenueInUsd: string;
    protocolInterestRevenueInUsd: string;
    protocolLiquidationRevenueInUsd: string;
    supplierRevenueInUsd: string;
    tonicPrice: string;
    supplyIncentive: string;
    supplyIncentiveInUsd: string;
    borrowIncentive: string;
    borrowIncentiveInUsd: string;
    totalSupplyIncentive: string;
    totalSupplyIncentiveInUsd: string;
    totalBorrowIncentive: string;
    totalBorrowIncentiveInUsd: string;
    supplierUniqueAddressAmount: string;
    borrowerUniqueAddressAmount: string;
    liquidatedUniqueAddressAmount: string;
    liquidatorUniqueAddressAmount: string;
    totalSupplierUniqueAddressAmount: string;
    totalBorrowerUniqueAddressAmount: string;
  }[];
}
export interface NewestFinancialStatementResult {
  financialStatementsPerDays: {
    id: string;
    date: number;
    sumOfSuppliesInUsd: string;
    sumOfBorrowsInUsd: string;
    totalUtilization: string;
  }[];
}

export interface StakeNFTInfosResult {
  id: string;
  user: string;
  poolId: number;
  nftContract: string;
  tokenId: string;
  active: boolean;
  stakedSince: string;
}

export interface TonicCirculatingSupplyResult {
  tonicSupply: {
    burnedSupply: string;
    circulatingSupply: string;
    totalSupply: string;
  };
}

export interface RevenuePaidToStakerResult {
  markets: {
    id: string;
    symbol: string;
    totalPaidToStakerInUsd: string;
  }[];
}

export enum ActionType {
  Supply,
  Borrow,
  Repay,
  RepayWithCollateral,
  Withdraw,
  CollateralSwap,
  Short,
  ClaimTonicFromMarkets,
  BoostMarkets,
  StakeTonicForxTONIC,
  StakexTONICToVaults,
  HarvestTonicFromVaults,
}

export interface TransactionHistoriesResult {
  transactionHistories: {
    account: string;
    action: ActionType;
    date: number;
    extraInfo: string;
    txHash: string;
  }[];
}

export const FINANCIALS_ADDRESS_AMOUNTS_QUERY = gql`
  query financialsAddressAmounts(
    $market: String!
    $orderDirection: String!
    $first: Int!
  ) {
    financialStatementsPerDays(
      first: $first
      orderBy: date
      orderDirection: $orderDirection
      where: { market: $market }
    ) {
      id
      date
      totalSupplierUniqueAddressAmount
      totalBorrowerUniqueAddressAmount
      market
    }
  }
`;

export interface FinancialsDataResponse {
  financialStatementsPerDays: FinancialsData[];
}

export interface FinancialsSupplyingDataResponse {
  financialStatementsPerDays: Omit<
    FinancialsData,
    | 'totalBorrowInUsd'
    | 'totalUtilization'
    | 'sumOfBorrowsInUsd'
    | 'sumOfRepaysInUsd'
  >[];
}

export interface FinancialsBorrowingDataResponse {
  financialStatementsPerDays: Omit<
    FinancialsData,
    | 'totalSupplyInUsd'
    | 'totalUtilization'
    | 'sumOfSuppliesInUsd'
    | 'sumOfRedeemsInUsd'
  >[];
}

interface FinancialsData {
  date: number;
  sumOfSuppliesInUsd: string;
  sumOfRedeemsInUsd: string;
  sumOfBorrowsInUsd: string;
  sumOfRepaysInUsd: string;
  totalSupplyInUsd: string;
  totalBorrowInUsd: string;
  totalUtilization: string;
}

export interface StakeNFTInfosDataResponse {
  stakeNftInfos: StakeNFTInfosResult[];
}

export interface BadDebtResponse {
  updated: string;
  total: string;
  decimals: string;
  tvl: string;
  users: string[];
}

export const ACCOUNT_QUERY = gql`
  query account($address: ID!) {
    account(id: $address) {
      stakedAmount
    }
  }
`;

export const STAKE_EVENTS_QUERY = gql`
  query stakeEvents($address: ID!) {
    stakeEvents(orderBy: blockNumber, where: { account: $address }) {
      account {
        id
      }
      blockNumber
      tonicStaked
      xTonicMinted
    }
  }
`;

export const RELEASE_EVENTS_QUERY = gql`
  query releaseEvents($address: ID!) {
    releaseEvents(orderBy: blockNumber, where: { account: $address }) {
      account {
        id
      }
      blockNumber
      xTonicBurned
      tonicReleased
    }
  }
`;

export const XTONIC_STATS_QUERY = gql`
  query xtonicStats {
    xtonicStats(id: 1) {
      xTonicPriceInUsd
      xTonicTotalSupply
      xTonicMarketCap
      xTonicExchangeRate
      stakedTonic
      avgLockTime
      apr7d
    }
  }
`;

export const XTONIC_ONE_MONTH_APRS_QUERY = gql`
  query xtonicAprsPerDay($first: Int!) {
    xtonicAprsPerDays(first: $first, orderBy: id, orderDirection: desc) {
      id
      date
      apr7d
    }
  }
`;

export const XTONIC_SIX_MONTHS_APRS_QUERY = gql`
  query xtonicAprsPerDay($ids: [ID!]!) {
    xtonicAprsPerDays(
      orderBy: date
      orderDirection: desc
      where: { id_in: $ids }
    ) {
      id
      date
      apr7d
    }
  }
`;

export const TRANSACTION_EVENT_COUNT_QUERY = gql`
  query transactionEventCount {
    transactionEventCount(id: 1) {
      id
      allCount
      supplyCount
      redeemCount
      borrowCount
      repayCount
      liquidationCount
    }
  }
`;

export const TRANSACTION_EVENTS_QUERY = gql`
  query transactionEvents($skip: Int!, $type: String) {
    transactionEvents(
      first: 10
      skip: $skip
      orderBy: date
      orderDirection: desc
      where: { type: $type }
    ) {
      id
      date
      type
      tokenAmount
      tokenSymbol
      valueInUsd
      address
    }
  }
`;

export const NEWEST_FINANCIAL_STATEMENT_QUERY = gql`
  query transactionEvents($skip: Int!) {
    financialStatementsPerDays(
      first: 1
      orderBy: date
      orderDirection: desc
      where: { market: "_ALL" }
    ) {
      id
      date
      sumOfSuppliesInUsd
      sumOfBorrowsInUsd
      totalUtilization
    }
  }
`;

export const NUM_FINANCIAL_STATEMENT_QUERY = gql`
  query transactionEvents($orderDirection: String!) {
    financialStatementsPerDays(
      first: 1
      orderBy: date
      orderDirection: $orderDirection
      where: { market: "_ALL" }
    ) {
      id
    }
  }
`;

export const FINANCIAL_STATEMENTS_PER_DAY_QUERY = gql`
  query transactionEvents(
    $first: Int
    $skip: Int!
    $orderBy: String!
    $orderDirection: String!
  ) {
    financialStatementsPerDays(
      first: $first
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: { market: "_ALL" }
    ) {
      id
      market
      date
      sumOfSuppliesInUsd
      sumOfRedeemsInUsd
      sumOfBorrowsInUsd
      sumOfRepaysInUsd
      sumOfLiquidationsInUsd
      totalSupplyInUsd
      totalBorrowInUsd
      sumOfAccruedInterestInUsd
      totalUtilization
      protocolRevenueInUsd
      protocolInterestRevenueInUsd
      protocolLiquidationRevenueInUsd
      supplierRevenueInUsd
      tonicPrice
      supplyIncentive
      supplyIncentiveInUsd
      borrowIncentive
      borrowIncentiveInUsd
      totalSupplyIncentive
      totalSupplyIncentiveInUsd
      totalBorrowIncentive
      totalBorrowIncentiveInUsd
      supplierUniqueAddressAmount
      borrowerUniqueAddressAmount
      liquidatedUniqueAddressAmount
      liquidatorUniqueAddressAmount
      totalSupplierUniqueAddressAmount
      totalBorrowerUniqueAddressAmount
    }
  }
`;

// remove deprecated cdcETH address
export const TONIC_ASSIST_INFOS_QUERY = gql`
  query tonicAssistInfos {
    tonicAssistInfos(
      where: { id_not: "0x9c2438f4ce3ea13b74f97df31fbb9e364771f6fe" }
    ) {
      market
      totalSupplierUniqueAddressAmount
      totalBorrowerUniqueAddressAmount
    }
  }
`;

export const TRANSACTION_EVENTS_QUERY_NO_TYPE = gql`
  query transactionEvents($skip: Int!, $type: String) {
    transactionEvents(
      first: 10
      skip: $skip
      orderBy: date
      orderDirection: desc
    ) {
      id
      date
      type
      tokenAmount
      tokenSymbol
      valueInUsd
      address
    }
  }
`;

export const FINANCIALS_DATA_QUERY = gql`
  query FinancialsData {
    financialStatementsPerDays(
      first: 100
      orderBy: date
      orderDirection: desc
      where: { market: "_ALL" }
    ) {
      date
      sumOfSuppliesInUsd
      sumOfRedeemsInUsd
      sumOfBorrowsInUsd
      sumOfRepaysInUsd
      totalSupplyInUsd
      totalBorrowInUsd
      totalUtilization
    }
  }
`;

export const FINANCIALS_SUPPLYING_DATA_QUERY = gql`
  query FinancialsSupplyingData($market: String!) {
    financialStatementsPerDays(
      first: 100
      orderBy: date
      orderDirection: desc
      where: { market: $market }
    ) {
      date
      sumOfSuppliesInUsd
      sumOfRedeemsInUsd
      totalSupplyInUsd
    }
  }
`;

export const FINANCIALS_BORROWING_DATA_QUERY = gql`
  query FinancialsBorrowingData($market: String!) {
    financialStatementsPerDays(
      first: 100
      orderBy: date
      orderDirection: desc
      where: { market: $market }
    ) {
      date
      sumOfBorrowsInUsd
      sumOfRepaysInUsd
      totalBorrowInUsd
    }
  }
`;

export const STAKE_NFT_INFORMATION_QUERY = gql`
  query stakeNFTInformation($nftContract: String!, $tokenId: String!) {
    stakeNftInfos(where: { nftContract: $nftContract, tokenId: $tokenId }) {
      id
      user
      poolId
      nftContract
      tokenId
      active
      stakedSince
    }
  }
`;

export const TONIC_CIRCULATING_SUPPLY_QUERY = gql`
  query tonicSupply {
    tonicSupply(id: 1) {
      burnedSupply
      circulatingSupply
      totalSupply
    }
  }
`;

export const REVENUE_PAID_STAKER_QUERY = gql`
  query Market {
    markets(first: 20) {
      id
      symbol
      totalPaidToStakerInUsd
    }
  }
`;

export const TRANSACTION_HISTORIES_QUERY = gql`
  query transactionHistories(
    $account: ID!
    $first: Int
    $skip: Int!
    $orderBy: String!
    $orderDirection: String!
  ) {
    transactionHistories(
      first: $first
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: { account: $account }
    ) {
      account
      action
      date
      extraInfo
      txHash
    }
  }
`;
