import { createContext, memo, useMemo, ReactNode } from 'react';
import { BigNumber } from 'ethers';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk';
import { BoostType } from '@cronos-labs/tectonic-sdk/dist/types';

import { formatInputAmountToString } from '@lib/units';
import { isAmountLessThanAllowance } from '@lib/utils';
import { useTectonicSdk } from '@providers/TectonicSdkProvider';
import { TxStatus } from '@types';
import { UNLIMITED_ALLOWANCE_AMOUNT } from '@config/constants';
import BorrowModal from '@components/BorrowModal';
import ClaimTonicAirdropModal, {
  ClaimTonicAirdropModalProps as ClaimTonicAirdropModalPropsType,
} from '@components/ClaimTonicAirdropModal';
import EnableOrDisableAsCollateralModal from '@components/EnableOrDisableAsCollateralModal';
import RepayModal from '@components/RepayModal';
import SupplyModal from '@components/SupplyModal';
import TonicBalanceModal, {
  TonicBalanceModalProps as TonicBalanceModalPropsType,
} from '@components/TonicBalanceModal';
import TonicStakingModal from '@components/TonicStakingModal';
import TonicStakingWithdrawModal from '@components/TonicStakingWithdrawModal';
import WithdrawModal from '@components/WithdrawModal';
import useGetTxStatus from '@hooks/useGetTxStatus';
import useWallets from '@hooks/useWallets';
import {
  NFT_BOOSTING_NFT_ADDRESS,
  getDeferLiquidityCheckAdapterAddress,
  getTectonicVaultProxy,
} from '@config/base';
import { getNetworkConfig } from '@config/baseNetworkConfig';
import useGetAllowance from '@hooks/useGetAllowance';
import LockDepositModal from '@components/LockDepositModal/LockDepositModal';
import UpgradeDepositsModal from '@components/UpgradeDepositsModal/UpgradeDepositsModal';
import HarvestTonicModal from '@components/HarvestTonicModal/HarvestTonicModal';
import WithdrawLockedDepositModal from '@components/WithdrawLockedDepositModal';
import Features from '@components/Features';
import ClaimBountyModal from '@components/ClaimBountyModal';
import usePoolMode from '@hooks/usePoolMode';
import { StakingNFTModal } from '@components/VaultsPageView/StakingNFT';
import { TxState } from 'hooks/useGetTxStatus';
import SwapModal from '@components/SwapModal/SwapModal';
import ShortModal from '@components/ShortModal';
import BoostModal from '@components/XTonicBoost/BoostModal';
import UpdateBoostModal from '@components/XTonicBoost/UpdateBoostModal';

import useTransactionModalsState, {
  ClaimBountyMeta,
  HarvestVaultTonicMeta,
  LockDepositMeta,
  UnstakeTonicMeta,
  UpgradeVaultMeta,
  UseTransactionModalsStateResult,
  WithdrawDepositMeta,
} from './useTransactionModalsState';

const { blockExplorerUrls } = getNetworkConfig();
const explorerUrl = blockExplorerUrls?.[0];

export interface TransactionModalsContextValue {
  onOpenModal: UseTransactionModalsStateResult['onOpenModal'];
  txState: Null<TxState>;
}

export const TransactionModalsContext =
  createContext<TransactionModalsContextValue>({
    onOpenModal: () => console.log('onOpenModal'),
    txState: null,
  });

interface TonicBalanceModalProps {
  estimateTonicInBatchData?: BigNumber;
  unclaimBoostTonicBalance?: BigNumber;
}

export interface TransactionModalsProviderProps {
  ClaimTonicAirdropModalProps?: Pick<
    ClaimTonicAirdropModalPropsType,
    'signature'
  >;
  TonicBalanceModalProps?: TonicBalanceModalProps;
  children: ReactNode;
  onTxSuccess?: () => void;
}

function TransactionModalsProvider({
  ClaimTonicAirdropModalProps,
  TonicBalanceModalProps,
  children,
  onTxSuccess,
}: TransactionModalsProviderProps): JSX.Element {
  const mainPoolSdk = useTectonicSdk('MAIN');
  const defiPoolSdk = useTectonicSdk('DEFI');
  const venoPoolSdk = useTectonicSdk('VENO');
  const mode = usePoolMode();
  const sdk = useTectonicSdk(mode);

  const { currentAccount } = useWallets();
  const tectonicVaultProxyAddress = getTectonicVaultProxy();
  const tectonicDeferLiquidityCheckAdapterAddress =
    getDeferLiquidityCheckAdapterAddress();

  const {
    onAmountChange,
    onCloseModal,
    onModalClosed,
    onOpenModal,
    state: modalState,
  } = useTransactionModalsState();
  const { getTxStatus, resetTxStatus, txState } = useGetTxStatus(modalState);
  const { asset } = modalState;

  const contextValue = useMemo<TransactionModalsContextValue>(
    () => ({
      onOpenModal,
      txState,
    }),
    [onOpenModal, txState]
  );

  const {
    allowance,
    asset: allowanceAsset,
    loaded: isAllowanceLoaded,
    loading: isAllowanceLoading,
  } = useGetAllowance(asset, currentAccount, mode);

  const transactionExplorerHref = useMemo(() => {
    const hasTxHash =
      txState?.status === TxStatus.pending ||
      txState?.status === TxStatus.confirmed;

    if (hasTxHash) {
      return `${explorerUrl}/tx/${txState.txHash}`;
    }

    return null;
  }, [txState]);

  const transactionErrorMessage = useMemo(() => {
    const hasErrorMessage = txState?.status === TxStatus.failed;
    if (hasErrorMessage) {
      return txState.errorMessage;
    }

    return null;
  }, [txState]);

  // * replaced with `claimTonicFromHelper`
  // const onClaimTonic = () => {
  //   if (currentAccount) {
  //     return getTxStatus(
  //       new Promise((resolve) => resolve(sdk.Tonic.claim(currentAccount))),
  //       onTxSuccess
  //     );
  //   }
  // };

  const onLockUnclaimTonic = (
    depositPercentage: string,
    vaultPoolId: number
  ) => {
    if (currentAccount) {
      const formattedPercentage = formatInputAmountToString(
        depositPercentage,
        18
      );
      return getTxStatus(
        new Promise((resolve) =>
          resolve(
            sdk.Tonic.claimTonicFromHelper(
              currentAccount,
              [
                mainPoolSdk.getTectonicCore().address,
                venoPoolSdk.getTectonicCore().address,
                defiPoolSdk.getTectonicCore().address,
              ],
              mainPoolSdk.getTectonicBoost().address,
              formattedPercentage,
              vaultPoolId
            )
          )
        ),
        onTxSuccess
      );
    }
  };

  const onClaimNonTonicTokens = (partnerTokenAddresses: string[]) => {
    if (currentAccount) {
      return getTxStatus(
        new Promise((resolve) =>
          resolve(
            sdk.Tonic.claimNonTonicTokensFromHelper(
              partnerTokenAddresses,
              currentAccount,
              [
                // venoPoolSdk.getTokenRewarder().address,
                defiPoolSdk.getTokenRewarder().address,
              ]
            )
          )
        ),
        onTxSuccess
      );
    }
  };

  const onEnable = () => {
    if (asset) {
      return getTxStatus(
        new Promise((resolve) =>
          resolve(
            sdk.ERC20.approve(
              asset.underlyingAddress,
              asset.tTokenAddress,
              UNLIMITED_ALLOWANCE_AMOUNT
            )
          )
        )
      );
    }
  };

  const onSupply = (amount: string) => {
    if (asset) {
      const formattedAmount = formatInputAmountToString(amount, asset.decimals);
      return getTxStatus(
        new Promise((resolve) =>
          resolve(sdk.Supply.mint(asset.tTokenAddress, formattedAmount))
        ),
        onTxSuccess
      );
    }
  };

  const onWithdraw = () => {
    if (asset && currentAccount) {
      return getTxStatus(
        new Promise((resolve) =>
          resolve(sdk.Supply.redeemAll(asset.tTokenAddress, currentAccount))
        ),
        onTxSuccess
      );
    }
  };

  const onWithdrawSome = (amount: string) => {
    if (asset) {
      const formattedAmount = formatInputAmountToString(amount, asset.decimals);
      return getTxStatus(
        new Promise((resolve) =>
          resolve(sdk.Supply.redeemSome(asset.tTokenAddress, formattedAmount))
        ),
        onTxSuccess
      );
    }
  };

  const onRepay = () => {
    if (asset && currentAccount) {
      return getTxStatus(
        new Promise((resolve) =>
          resolve(sdk.Borrow.repayAll(asset.tTokenAddress, currentAccount))
        ),
        onTxSuccess
      );
    }
  };

  const onRepaySome = (amount: string) => {
    if (asset) {
      const formattedAmount = formatInputAmountToString(amount, asset.decimals);
      return getTxStatus(
        new Promise((resolve) =>
          resolve(sdk.Borrow.repaySome(asset.tTokenAddress, formattedAmount))
        ),
        onTxSuccess
      );
    }
  };

  const onRepayAllWithCollateral = (
    tectonicSocketAddress: string,
    account: string,
    repayAmount: BigNumber,
    slippageTolerance: BigNumber,
    path: string[],
    tTokenAddressOfCollateral: string
  ) => {
    if (asset && currentAccount) {
      return getTxStatus(
        new Promise((resolve) =>
          resolve(
            sdk.Borrow.repayAllWithCollateral(
              tectonicSocketAddress,
              account,
              repayAmount,
              slippageTolerance,
              path,
              tTokenAddressOfCollateral
            )
          )
        ),
        onTxSuccess
      );
    }
  };

  const onRepaySomeWithCollateral = (
    tectonicSocketAddress: string,
    account: string,
    repayAmount: BigNumber,
    slippageTolerance: BigNumber,
    path: string[],
    tTokenAddressOfCollateral: string
  ) => {
    if (asset) {
      return getTxStatus(
        new Promise((resolve) =>
          resolve(
            sdk.Borrow.repaySomeWithCollateral(
              tectonicSocketAddress,
              account,
              repayAmount,
              slippageTolerance,
              path,
              tTokenAddressOfCollateral
            )
          )
        ),
        onTxSuccess
      );
    }
  };

  const onCollateralSwap = (
    tectonicSocketAddress: string,
    account: string,
    swapAmount: BigNumber,
    slippageTolerance: BigNumber,
    path: string[],
    swapTTokenAddress: string
  ) => {
    if (asset) {
      return getTxStatus(
        new Promise((resolve) =>
          resolve(
            sdk.Borrow.swapAndDeposit(
              tectonicSocketAddress,
              account,
              swapAmount,
              slippageTolerance,
              path,
              swapTTokenAddress
            )
          )
        ),
        onTxSuccess
      );
    }
  };

  const onGetTTokenAmountIn = async (
    tectonicSocketAddress: string,
    repayAmount: BigNumber,
    slippageTolerance: BigNumber,
    path: string[]
  ): Promise<[BigNumber, BigNumber]> => {
    const tTokenAmountIn = await sdk.Borrow.getTTokenAmountIn(
      tectonicSocketAddress,
      repayAmount,
      slippageTolerance,
      path
    );

    return tTokenAmountIn;
  };

  const onGetTTokenAmountOut = async (
    tectonicSocketAddress: string,
    fromAmount: BigNumber,
    slippageTolerance: BigNumber,
    path: string[]
  ): Promise<[BigNumber, BigNumber]> => {
    const tTokenAmountOut = await sdk.getTTokenAmountOut(
      tectonicSocketAddress,
      fromAmount,
      slippageTolerance,
      path
    );

    return tTokenAmountOut;
  };

  const onShortLongToken = (
    tectonicSocketAddress: string,
    longAmount: BigNumber,
    shortAmount: BigNumber,
    slippageTolerance: BigNumber,
    dexes: string[],
    paths: string[][],
    isLongCRO: boolean
  ) => {
    if (asset) {
      return getTxStatus(
        new Promise((resolve) =>
          resolve(
            sdk.Order.createLongNShortPosition(
              tectonicSocketAddress,
              longAmount,
              shortAmount,
              slippageTolerance,
              dexes,
              paths,
              isLongCRO
            )
          )
        ),
        onTxSuccess
      );
    }
  };

  const onBoostMarkets = (tTokens: string[], boostTypes: BoostType[]) => {
    return getTxStatus(
      new Promise((resolve) =>
        resolve(sdk.Boost.boostMarkets(tTokens, boostTypes))
      ),
      onTxSuccess
    );
  };

  const onReplaceBoostMarkets = (
    oldTTokens: string[],
    oldBoostTypes: BoostType[],
    newTTokens: string[],
    newBoostTypes: BoostType[]
  ) => {
    return getTxStatus(
      new Promise((resolve) =>
        resolve(
          sdk.Boost.replaceBoostMarkets(
            oldTTokens,
            oldBoostTypes,
            newTTokens,
            newBoostTypes
          )
        )
      ),
      onTxSuccess
    );
  };

  const onRemoveBoostMarkets = (tTokens: string[], boostTypes: BoostType[]) => {
    return getTxStatus(
      new Promise((resolve) =>
        resolve(sdk.Boost.removeBoostMarkets(tTokens, boostTypes))
      ),
      onTxSuccess
    );
  };

  const onShortLongPositionByWowmax = (
    tectonicSocketAddress: string,
    longAmount: BigNumber,
    shortAmount: BigNumber,
    longToken: string,
    shortToken: string,
    swapData: string,
    isLongCRO: boolean
  ) => {
    if (asset) {
      return getTxStatus(
        new Promise((resolve) =>
          resolve(
            sdk.Order.createLongNShortPositionByWowmax(
              tectonicSocketAddress,
              longAmount,
              shortAmount,
              longToken,
              shortToken,
              swapData,
              isLongCRO
            )
          )
        ),
        onTxSuccess
      );
    }
  };

  const onBorrow = (amount: string) => {
    if (asset) {
      const formattedAmount = formatInputAmountToString(amount, asset.decimals);
      return getTxStatus(
        new Promise((resolve) =>
          resolve(sdk.Borrow.borrow(asset.tTokenAddress, formattedAmount))
        ),
        onTxSuccess
      );
    }
  };

  const onEnableAsCollateral = (tokenAsset?: TectonicAsset) => {
    if (asset) {
      return getTxStatus(
        new Promise((resolve) =>
          resolve(
            sdk.Supply.enableCollateral(
              tokenAsset ? tokenAsset.tTokenAddress : asset.tTokenAddress
            )
          )
        ),
        onTxSuccess
      );
    }
  };

  const onDisableAsCollateral = () => {
    if (asset) {
      return getTxStatus(
        new Promise((resolve) =>
          resolve(sdk.Supply.disableCollateral(asset.tTokenAddress))
        ),
        onTxSuccess
      );
    }
  };

  const onStake = (tonicAmount: string) => {
    if (currentAccount) {
      return getTxStatus(
        new Promise((resolve) =>
          resolve(sdk.Staking.stake(currentAccount, tonicAmount))
        ),
        onTxSuccess
      );
    }
  };

  const onUnstake = (xTonicAmount: string) => {
    if (currentAccount) {
      return getTxStatus(
        new Promise((resolve) =>
          resolve(sdk.Staking.unstake(currentAccount, xTonicAmount))
        ),
        onTxSuccess
      );
    }
  };

  const onWithdrawUnstakedTonic = () => {
    return getTxStatus(
      new Promise((resolve) => resolve(sdk.Staking.release())),
      onTxSuccess
    );
  };

  function onClaimTonicAirdrop(amount: string, signature: string) {
    if (currentAccount) {
      return getTxStatus(
        new Promise((resolve) =>
          resolve(sdk.Tonic.claimFromAirDropper(amount, signature))
        ),
        onTxSuccess
      );
    }
  }
  function onClaimBounty() {
    return getTxStatus(sdk.Staking.pullAndConvert(), onTxSuccess);
  }
  function onEnableVaults() {
    return getTxStatus(sdk.Vault.approve(), onTxSuccess);
  }
  function onHarvestVaultTonic() {
    return getTxStatus(sdk.Vault.harvest(), onTxSuccess);
  }
  function onWithdrawDeposit() {
    const stakeIds = (modalState.meta as WithdrawDepositMeta)?.stakeIds;
    if (stakeIds === undefined || !stakeIds.length) {
      return;
    }
    return getTxStatus(sdk.Vault.batchWithdraw(stakeIds), onTxSuccess);
  }
  async function onLock(poolId: number, amount: string) {
    if (!asset || !currentAccount) {
      return;
    }

    const formattedAmount = formatInputAmountToString(amount, asset.decimals);
    return getTxStatus(
      sdk.Vault.deposit(poolId, formattedAmount, currentAccount),
      onTxSuccess
    );
  }
  async function onUpgrade(ids: number[], poolId: number) {
    if (!ids.length) {
      return;
    }

    return getTxStatus(
      sdk.Vault.batchUpgrade(
        ids,
        Array.from({
          length: ids.length,
        }).map(() => poolId)
      ),
      onTxSuccess
    );
  }

  const isApproveForALl = async (): Promise<boolean> => {
    if (!currentAccount) return false;

    const approved = await sdk.ERC721.isApproveForAll(
      NFT_BOOSTING_NFT_ADDRESS[0],
      currentAccount,
      tectonicVaultProxyAddress
    );

    return approved ?? false;
  };

  const handleEnableNFTs = async () => {
    const isApproveForAllErc721 = await isApproveForALl();

    if (!isApproveForAllErc721) {
      await getTxStatus(
        sdk.ERC721.approveForAll(
          NFT_BOOSTING_NFT_ADDRESS[0],
          tectonicVaultProxyAddress
        )
      );
    }
  };

  const isApproveLongTokenAsset = async (longTokenAsset: TectonicAsset) => {
    if (longTokenAsset && currentAccount) {
      const allowance = await sdk.ERC20.allowanceOf(
        longTokenAsset.underlyingAddress,
        currentAccount,
        tectonicDeferLiquidityCheckAdapterAddress
      );

      return allowance ?? BigNumber.from('0');
    }
    return BigNumber.from('0');
  };

  const onEnableLongToken = async (
    longTokenAsset: TectonicAsset,
    amount: string
  ) => {
    const allowance = await isApproveLongTokenAsset(longTokenAsset);
    const erc20IsEnabled = isAmountLessThanAllowance(
      allowance,
      amount,
      longTokenAsset
    );
    if (longTokenAsset && !erc20IsEnabled) {
      return await getTxStatus(
        sdk.ERC20.approve(
          longTokenAsset.underlyingAddress,
          tectonicDeferLiquidityCheckAdapterAddress,
          UNLIMITED_ALLOWANCE_AMOUNT
        )
      );
    }
  };

  async function handleStakeUnstakeNFTs(
    nftToStakeIds: BigNumber[],
    nftToUnstakeIds: BigNumber[],
    poolId: number
  ) {
    if (nftToStakeIds.length === 0 && nftToUnstakeIds.length === 0) {
      return;
    }

    if (nftToStakeIds.length > 0 && nftToUnstakeIds.length > 0) {
      return getTxStatus(
        sdk.Vault.replaceNFTs(
          NFT_BOOSTING_NFT_ADDRESS[0],
          nftToUnstakeIds,
          nftToStakeIds,
          poolId
        ),
        onTxSuccess
      );
    } else if (nftToStakeIds.length > 0) {
      return getTxStatus(
        sdk.Vault.stakeNFTs(NFT_BOOSTING_NFT_ADDRESS[0], nftToStakeIds, poolId),
        onTxSuccess
      );
    } else {
      return getTxStatus(
        sdk.Vault.unstakeNFTs(
          NFT_BOOSTING_NFT_ADDRESS[0],
          nftToUnstakeIds,
          poolId
        ),
        onTxSuccess
      );
    }
  }

  function onAfterLeave(): void {
    onModalClosed();
    resetTxStatus();
  }

  // headlessUI library not callback onAfterLeave
  function onclosedModal(): void {
    onModalClosed();
    onCloseModal();
    resetTxStatus();
  }

  return (
    <>
      {modalState.activeModal === 'supply' && (
        <SupplyModal
          afterLeave={onAfterLeave}
          allowance={allowance}
          amount={modalState.amount}
          asset={modalState.asset}
          isAllowanceLoaded={
            isAllowanceLoaded &&
            allowanceAsset?.symbol === modalState.asset?.symbol
          }
          isAllowanceLoading={isAllowanceLoading}
          isOpen={modalState.activeModal === 'supply'}
          onAmountChange={onAmountChange}
          onClose={onclosedModal}
          onEnable={onEnable}
          onSupply={onSupply}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
          mode={mode}
        />
      )}
      {modalState.activeModal === 'withdraw' && (
        <WithdrawModal
          afterLeave={onAfterLeave}
          amount={modalState.amount}
          asset={modalState.asset}
          isOpen={modalState.activeModal === 'withdraw'}
          onAmountChange={onAmountChange}
          onClose={onclosedModal}
          onWithdraw={onWithdraw}
          onWithdrawSome={onWithdrawSome}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
          mode={mode}
        />
      )}
      {modalState.activeModal === 'swap' && (
        <SwapModal
          afterLeave={onAfterLeave}
          allowance={allowance}
          amount={modalState.amount}
          asset={modalState.asset}
          isAllowanceLoaded={
            isAllowanceLoaded &&
            allowanceAsset?.symbol === modalState.asset?.symbol
          }
          isAllowanceLoading={isAllowanceLoading}
          isOpen={modalState.activeModal === 'swap'}
          onAmountChange={onAmountChange}
          onClose={onclosedModal}
          onEnable={onEnable}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
          mode={mode}
          onGetTTokenAmountOut={onGetTTokenAmountOut}
          onCollateralSwap={onCollateralSwap}
        />
      )}
      {modalState.activeModal === 'boost' && (
        <BoostModal
          isOpen={modalState.activeModal === 'boost'}
          mode={mode}
          onClose={onclosedModal}
          afterLeave={onAfterLeave}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
          onBoostMarkets={onBoostMarkets}
        />
      )}
      {modalState.activeModal === 'updateBoost' && (
        <UpdateBoostModal
          isOpen={modalState.activeModal === 'updateBoost'}
          mode={mode}
          onClose={onclosedModal}
          afterLeave={onAfterLeave}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
          onReplaceBoostMarkets={onReplaceBoostMarkets}
          onRemoveBoostMarkets={onRemoveBoostMarkets}
        />
      )}
      {modalState.activeModal === 'repay' && (
        <RepayModal
          afterLeave={onAfterLeave}
          allowance={allowance}
          amount={modalState.amount}
          asset={modalState.asset}
          isAllowanceLoaded={
            isAllowanceLoaded &&
            allowanceAsset?.symbol === modalState.asset?.symbol
          }
          isAllowanceLoading={isAllowanceLoading}
          isOpen={modalState.activeModal === 'repay'}
          onAmountChange={onAmountChange}
          onClose={onclosedModal}
          onEnable={onEnable}
          onRepay={onRepay}
          onRepaySome={onRepaySome}
          onRepayAllWithCollateral={onRepayAllWithCollateral}
          onRepaySomeWithCollateral={onRepaySomeWithCollateral}
          onGetTTokenAmountIn={onGetTTokenAmountIn}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
          mode={mode}
        />
      )}
      {modalState.activeModal === 'short' && (
        <ShortModal
          afterLeave={onAfterLeave}
          amount={modalState.amount}
          asset={modalState.asset}
          isOpen={modalState.activeModal === 'short'}
          onAmountChange={onAmountChange}
          onClose={onclosedModal}
          onShortLongToken={onShortLongToken}
          onShortLongPositionByWowmax={onShortLongPositionByWowmax}
          onGetTTokenAmountOut={onGetTTokenAmountOut}
          onEnableLongToken={onEnableLongToken}
          onEnableAsCollateral={onEnableAsCollateral}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
          mode={mode}
        />
      )}
      {modalState.activeModal === 'borrow' && (
        <BorrowModal
          afterLeave={onAfterLeave}
          amount={modalState.amount}
          asset={modalState.asset}
          isOpen={modalState.activeModal === 'borrow'}
          onAmountChange={onAmountChange}
          onBorrow={onBorrow}
          onClose={onclosedModal}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
          mode={mode}
        />
      )}
      {modalState.activeModal === 'collateral' && (
        <EnableOrDisableAsCollateralModal
          afterLeave={onAfterLeave}
          asset={modalState.asset}
          isOpen={modalState.activeModal === 'collateral'}
          onClose={onclosedModal}
          onDisableAsCollateral={onDisableAsCollateral}
          onEnableAsCollateral={onEnableAsCollateral}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
          mode={mode}
        />
      )}
      {modalState.activeModal === 'tonicBalance' && (
        <TonicBalanceModal
          afterLeave={onAfterLeave}
          asset={modalState.asset}
          isOpen={modalState.activeModal === 'tonicBalance'}
          onLockClaim={onLockUnclaimTonic}
          onClaimPartnerTokens={onClaimNonTonicTokens}
          onClose={onclosedModal}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
          {...(TonicBalanceModalProps ?? {})}
        />
      )}
      {modalState.activeModal === 'stakeTonic' && (
        <TonicStakingModal
          afterLeave={onAfterLeave}
          amount={modalState.amount}
          asset={modalState.asset}
          hasCooldown={
            (modalState.meta as UnstakeTonicMeta)?.hasCooldown ?? false
          }
          isOpen={modalState.activeModal === 'stakeTonic'}
          mode="stake"
          onClose={onclosedModal}
          onStartTransaction={onStake}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
          xTonicToTonicExchangeRate={
            (modalState.meta as UnstakeTonicMeta)?.xTonicToTonicExchangeRate ??
            null
          }
        />
      )}
      {modalState.activeModal === 'unstakeTonic' && (
        <TonicStakingModal
          afterLeave={onAfterLeave}
          amount={modalState.amount}
          asset={modalState.asset}
          hasCooldown={
            (modalState.meta as UnstakeTonicMeta)?.hasCooldown ?? false
          }
          isOpen={modalState.activeModal === 'unstakeTonic'}
          mode="unstake"
          onClose={onclosedModal}
          onStartTransaction={onUnstake}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
          xTonicToTonicExchangeRate={
            (modalState.meta as UnstakeTonicMeta)?.xTonicToTonicExchangeRate ??
            null
          }
        />
      )}
      {modalState.activeModal === 'withdrawUnstakedTonic' && (
        <TonicStakingWithdrawModal
          afterLeave={onAfterLeave}
          amount={modalState.amount}
          asset={modalState.asset}
          isOpen={modalState.activeModal === 'withdrawUnstakedTonic'}
          onClose={onclosedModal}
          onWithdraw={onWithdrawUnstakedTonic}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
        />
      )}
      {modalState.activeModal === 'claimTonicAirdrop' && (
        <ClaimTonicAirdropModal
          afterLeave={onAfterLeave}
          amount={modalState.amount}
          asset={modalState.asset}
          isOpen={modalState.activeModal === 'claimTonicAirdrop'}
          onClaimTonicAirdrop={onClaimTonicAirdrop}
          onClose={onclosedModal}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
          {...(ClaimTonicAirdropModalProps ?? {})}
        />
      )}
      {modalState.activeModal === 'claimBounty' && (
        <ClaimBountyModal
          afterLeave={onAfterLeave}
          asset={modalState.asset}
          isOpen={modalState.activeModal === 'claimBounty'}
          formattedAmount={
            (modalState.meta as ClaimBountyMeta)?.formattedAmount
          }
          onClaimBounty={onClaimBounty}
          onClose={onclosedModal}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
        />
      )}
      <Features.Vaults>
        {modalState.activeModal === 'lockDeposit' && (
          <LockDepositModal
            afterLeave={onAfterLeave}
            amount={modalState.amount}
            key={
              'lockDeposit' +
              (modalState.activeModal === 'lockDeposit').toString()
            }
            preselectedPeriod={
              (modalState.meta as LockDepositMeta)?.preselectedPeriod
            }
            isOpen={modalState.activeModal === 'lockDeposit'}
            onClose={onclosedModal}
            onAmountChange={onAmountChange}
            onLock={onLock}
            transactionErrorMessage={transactionErrorMessage}
            transactionExplorerHref={transactionExplorerHref}
            transactionStatus={txState?.status || null}
            onEnable={onEnableVaults}
          />
        )}
        {modalState.activeModal === 'upgradeVault' && (
          <UpgradeDepositsModal
            afterLeave={onAfterLeave}
            key={
              'upgradeVault' +
              (modalState.activeModal === 'upgradeVault').toString()
            }
            isOpen={modalState.activeModal === 'upgradeVault'}
            onClose={onclosedModal}
            transactionErrorMessage={transactionErrorMessage}
            transactionExplorerHref={transactionExplorerHref}
            transactionStatus={txState?.status || null}
            availablePeriods={
              (modalState.meta as UpgradeVaultMeta)?.availablePeriods || []
            }
            deposits={(modalState.meta as UpgradeVaultMeta)?.deposits || []}
            onUpgrade={onUpgrade}
          />
        )}
        {modalState.activeModal === 'harvestVaultTonic' && (
          <HarvestTonicModal
            afterLeave={onAfterLeave}
            tonicAmount={
              (modalState.meta as HarvestVaultTonicMeta)?.tonicAmount || '0'
            }
            onHarvest={onHarvestVaultTonic}
            transactionErrorMessage={transactionErrorMessage}
            transactionExplorerHref={transactionExplorerHref}
            transactionStatus={txState?.status || null}
            isOpen={modalState.activeModal === 'harvestVaultTonic'}
            onClose={onclosedModal}
          />
        )}
        {modalState.activeModal === 'withdrawDeposit' && (
          <WithdrawLockedDepositModal
            afterLeave={onAfterLeave}
            apr={(modalState.meta as WithdrawDepositMeta)?.apr || '-'}
            totalXTonicUnlocked={
              (modalState.meta as WithdrawDepositMeta)?.totalXTonicUnlocked ||
              '-'
            }
            multiplier={
              (modalState.meta as WithdrawDepositMeta)?.multiplier || '-'
            }
            onWithdraw={onWithdrawDeposit}
            isOpen={modalState.activeModal === 'withdrawDeposit'}
            onClose={onclosedModal}
            transactionErrorMessage={transactionErrorMessage}
            transactionExplorerHref={transactionExplorerHref}
            transactionStatus={txState?.status || null}
          />
        )}
      </Features.Vaults>
      {modalState.activeModal === 'nftStaking' && (
        <StakingNFTModal
          poolId={
            modalState.meta?.type === 'nftStaking'
              ? modalState.meta.poolId
              : undefined
          }
          onClose={onclosedModal}
          isOpen={modalState.activeModal === 'nftStaking'}
          onStakeUnstakeNFTs={handleStakeUnstakeNFTs}
          onEnableNFTs={handleEnableNFTs}
          afterLeave={onAfterLeave}
          transactionErrorMessage={transactionErrorMessage}
          transactionExplorerHref={transactionExplorerHref}
          transactionStatus={txState?.status || null}
        />
      )}
      <TransactionModalsContext.Provider value={contextValue}>
        {children}
      </TransactionModalsContext.Provider>
    </>
  );
}

export default memo(TransactionModalsProvider);
