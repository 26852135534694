import upperFirst from 'lodash/upperFirst';
import { ValueOf } from 'next/dist/shared/lib/constants';

export const PATHNAME: Record<string, string> = {
  home: '/',
  markets: '/markets',
  marketsAssets: '/markets/[assetSymbol]',
  dashboard: '/dashboard',
  dashboardVENO: '/dashboard/veno',
  dashboardDEFI: '/dashboard/defi',
  dashboardTransactionHistory: '/dashboard/transactionhistory',
  vaults: '/vaults',
  tonic: '/tonic',
  analytics: '/analytics',
} as const;

export const getNavBarLabel = (path: ValueOf<typeof PATHNAME>): string => {
  if (path === PATHNAME.vaults || path === PATHNAME.tonic) return 'Earn';
  return upperFirst(path.replace(/(^\/)|(\/$)/g, ''))
    .split('/')
    .map((item, index, arr) =>
      arr.length > 1 && index === arr.length - 1 ? item.toUpperCase() : item
    )
    .join('/');
};
