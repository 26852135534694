import Link, { LinkProps } from 'next/link';
import { Text } from '@tectonicfi/tectonic-ui-kit';
import clsx from 'clsx';

interface NavBarTabProps {
  className?: string;
  href: LinkProps['href'];
  label: string;
  selected: boolean;
}

function NavBarTab({
  className,
  href,
  label,
  selected,
}: NavBarTabProps): JSX.Element {
  return (
    <div className={clsx('relative flex flex-row items-center', className)}>
      <Link href={href} legacyBehavior>
        <a>
          <Text
            as="span"
            className={clsx(selected && 'text-yellowPrimary')}
            variant="semiBold"
          >
            {label}
          </Text>
        </a>
      </Link>
      <div
        className={clsx(
          'absolute -bottom-0.5 h-[2px] w-full border-solid',
          selected && 'bg-yellowPrimary'
        )}
      />
    </div>
  );
}

export default NavBarTab;
