import { useEffect, useReducer } from 'react';
import { BigNumber } from 'ethers';
import { TectonicAsset } from '@cronos-labs/tectonic-sdk/dist/types';

import useSdkAndSupportedAssets from '@hooks/useSdkAndSupportedAssets';
import useAppDispatch from '@hooks/useAppDispatch';
import useTectonicAssetPricer from '@hooks/useTectonicAssetPricer';
import { getAssetUsdPrice } from '@redux/usdPrices';
import { Mode } from '@components/MarketsPageView/types';

interface MarketDetailsCardDataState {
  borrowCap: BigNumber | null;
  borrowCapLoading: boolean;
  collateralFactor: number | null;
  collateralFactorLoading: boolean;
  exchangeRate: BigNumber | null;
  exchangeRateLoading: boolean;
  priceLoading: boolean;
  reserveFactor: number | null;
  reserveFactorLoading: boolean;
  reserves: BigNumber | null;
  reservesLoading: boolean;
  supplyRatePerBlock: BigNumber | null;
  supplyRatePerBlockLoading: boolean;
  tTokenMinted: BigNumber | null;
  tTokenMintedLoading: boolean;
}

const initState: MarketDetailsCardDataState = {
  priceLoading: true,
  borrowCap: null,
  borrowCapLoading: true,
  collateralFactor: null,
  collateralFactorLoading: true,
  exchangeRate: null,
  exchangeRateLoading: true,
  reserveFactor: null,
  reserveFactorLoading: true,
  reserves: null,
  reservesLoading: true,
  supplyRatePerBlock: null,
  supplyRatePerBlockLoading: true,
  tTokenMinted: null,
  tTokenMintedLoading: true,
};

const GET_FULFILLED = 'GET_FULFILLED';

const GET_REJECTED = 'GET_REJECTED';

const GET_PRICE_FULFILLED = 'GET_PRICE_FULFILLED';

const GET_PRICE_REJECTED = 'GET_PRICE_REJECTED';

type MarketDetailsCardDataField = keyof Pick<
  MarketDetailsCardDataState,
  | 'borrowCap'
  | 'collateralFactor'
  | 'exchangeRate'
  | 'reserveFactor'
  | 'reserves'
  | 'supplyRatePerBlock'
  | 'tTokenMinted'
>;

interface GetFulfilledAction {
  field: MarketDetailsCardDataField;
  type: typeof GET_FULFILLED;
  value: MarketDetailsCardDataState[MarketDetailsCardDataField];
}

interface GetPriceFulfilledAction {
  type: typeof GET_PRICE_FULFILLED;
}

interface GetPriceRejectedAction {
  type: typeof GET_PRICE_REJECTED;
}

interface GetRejectedAction {
  field: MarketDetailsCardDataField;
  type: typeof GET_REJECTED;
}

type MarketDetailsCardDataAction =
  | GetFulfilledAction
  | GetRejectedAction
  | GetPriceFulfilledAction
  | GetPriceRejectedAction;

function reducer(
  state: MarketDetailsCardDataState,
  action: MarketDetailsCardDataAction
): MarketDetailsCardDataState {
  switch (action.type) {
    case GET_PRICE_FULFILLED:
    case GET_PRICE_REJECTED:
      return {
        ...state,
        priceLoading: false,
      };
    case GET_FULFILLED:
      return {
        ...state,
        [action.field]: action.value,
        [`${action.field}Loading`]: false,
      };
    case GET_REJECTED:
      return { ...state, [`${action.field}Loading`]: false };
    default:
      return { ...state };
  }
}

type UseMarketDetailsCardDataResult = MarketDetailsCardDataState;

// deprecated, use `useMarketDetailsCardDataWithTokenInterestInfo` instead.
// TODO - Handle all errors below
function useMarketDetailsCardData(
  asset: TectonicAsset,
  mode: Mode
): UseMarketDetailsCardDataResult {
  const appDispatch = useAppDispatch();
  const { sdk } = useSdkAndSupportedAssets(mode);
  const tectonicAssetPricer = useTectonicAssetPricer(sdk);
  const [state, dispatch] = useReducer(reducer, initState);

  useEffect(() => {
    function dispatchFulfilled(
      field: MarketDetailsCardDataField,
      value: MarketDetailsCardDataState[MarketDetailsCardDataField]
    ): void {
      dispatch({ field, type: GET_FULFILLED, value });
    }

    function dispatchRejected(field: MarketDetailsCardDataField): void {
      dispatch({ field, type: GET_REJECTED });
    }

    async function getPrice(): Promise<void> {
      if (sdk) {
        try {
          await appDispatch(getAssetUsdPrice(tectonicAssetPricer, asset));
          dispatch({ type: GET_PRICE_FULFILLED });
        } catch (error) {
          dispatch({ type: GET_PRICE_REJECTED });
        }
      }
    }

    async function getBorrowCap(): Promise<void> {
      if (sdk) {
        try {
          const value = await sdk.Borrow.borrowCap(asset.tTokenAddress);
          dispatchFulfilled('borrowCap', value);
        } catch (error) {
          dispatchRejected('borrowCap');
        }
      }
    }

    async function getReserves(): Promise<void> {
      if (sdk) {
        try {
          const value = await sdk.Supply.reserves(asset.tTokenAddress);
          dispatchFulfilled('reserves', value);
        } catch (error) {
          dispatchRejected('reserves');
        }
      }
    }

    async function getReserveFactor(): Promise<void> {
      if (sdk) {
        try {
          const value = await sdk.Supply.reserveFactor(asset.tTokenAddress);
          dispatchFulfilled('reserveFactor', value);
        } catch (error) {
          dispatchRejected('reserveFactor');
        }
      }
    }

    async function getCollateralFactor(): Promise<void> {
      if (sdk) {
        try {
          const value = await sdk.Borrow.collateralFactor(asset.tTokenAddress);
          dispatchFulfilled('collateralFactor', value);
        } catch (error) {
          dispatchRejected('collateralFactor');
        }
      }
    }

    async function getTTokenMinted(): Promise<void> {
      if (sdk) {
        try {
          const value = await sdk.Supply.totalSuppliedAmountInMarket(
            asset.tTokenAddress
          );
          dispatchFulfilled('tTokenMinted', value);
        } catch (error) {
          dispatchRejected('tTokenMinted');
        }
      }
    }

    async function getExchangeRate(): Promise<void> {
      if (sdk) {
        try {
          const value = await sdk.Supply.exchangeRate(asset.tTokenAddress);
          dispatchFulfilled('exchangeRate', value);
        } catch (error) {
          dispatchRejected('exchangeRate');
        }
      }
    }

    async function getSupplyRatePerBlock(): Promise<void> {
      if (sdk) {
        try {
          const value = await sdk.Supply.supplyRatePerBlock(
            asset.tTokenAddress
          );
          dispatchFulfilled('supplyRatePerBlock', value);
        } catch (error) {
          dispatchRejected('supplyRatePerBlock');
        }
      }
    }

    getPrice();
    getBorrowCap();
    getReserves();
    getReserveFactor();
    getCollateralFactor();
    getTTokenMinted();
    getExchangeRate();
    getSupplyRatePerBlock();
  }, [appDispatch, asset, sdk, tectonicAssetPricer]);

  return state;
}

export default useMarketDetailsCardData;
