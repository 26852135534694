import { PoolType, getTectonicSocketAddress } from '@config/base';

export default function useSocketAddress(mode: PoolType): string {
  const socketAddress = getTectonicSocketAddress();

  switch (mode) {
    case 'MAIN':
      return socketAddress[0];

    case 'DEFI':
      return socketAddress[1];

    case 'VENO':
      return socketAddress[2];

    default:
      return socketAddress[0];
  }
}

export function useSocketAddressPool(address: Null<string>): PoolType {
  const socketAddress = getTectonicSocketAddress().map((addr: string) =>
    addr.toLowerCase()
  );

  switch (address) {
    case socketAddress[0]:
      return 'MAIN';

    case socketAddress[1]:
      return 'DEFI';

    case socketAddress[2]:
      return 'VENO';

    default:
      return 'MAIN';
  }
}
