export enum QueryKey {
  API_MARKET_HISTORY = 'tectonic-api:market-history',
  ENS_PROVIDER_ADDRESS = 'ens-provider:address',
  GRAPH_ACCOUNT = 'graph:account',
  GRAPH_ASSIST_INFOS = 'graph:assist-infos',
  GRAPH_VENO_ASSIST_INFOS = 'graph:veno-assist-infos',
  GRAPH_DEFI_ASSIST_INFOS = 'graph:defi-assist-infos',
  GRAPH_FINANCIAL_STATEMENTS_PER_DAY = 'graph:financial-statements-per-day',
  GRAPH_VENO_FINANCIAL_STATEMENTS_PER_DAY = 'graph:veno-financial-statements-per-day',
  GRAPH_DEFI_FINANCIAL_STATEMENTS_PER_DAY = 'graph:defi-financial-statements-per-day',
  GRAPH_NUM_FINANCIAL_STATEMENTS_PER_DAY = 'graph:num-financial-statements-per-day',
  GRAPH_VENO_NUM_FINANCIAL_STATEMENTS_PER_DAY = 'graph:veno-num-financial-statements-per-day',
  GRAPH_DEFI_NUM_FINANCIAL_STATEMENTS_PER_DAY = 'graph:defi-num-financial-statements-per-day',
  GRAPH_FINANCIALS_ADDRESS_AMOUNTS = 'graph:financials-address-amounts',
  GRAPH_VENO_FINANCIALS_ADDRESS_AMOUNTS = 'graph:veno-financials-address-amounts',
  GRAPH_DEFI_FINANCIALS_ADDRESS_AMOUNTS = 'graph:defi-financials-address-amounts',
  GRAPH_NEWEST_FINANCIAL_STATEMENT = 'graph:newest-financial-statement',
  GRAPH_RELEASE_EVENTS = 'graph:release-events',
  GRAPH_STAKE_EVENTS = 'graph:stake-events',
  GRAPH_TRANSACTION_EVENTS = 'graph:transaction-events',
  GRAPH_VENO_TRANSACTION_EVENTS = 'graph:veno-transaction-events',
  GRAPH_DEFI_TRANSACTION_EVENTS = 'graph:defi-transaction-events',
  GRAPH_XTONIC_STATS = 'graph:xtonic-stats',
  GRAPH_XTONIC_APRS = 'graph:xtonic-aprs',
  GRAPH_FINANCIALS_DATA = 'graph:financials-data',
  GRAPH_VENO_FINANCIALS_DATA = 'graph:veno-financials-data',
  GRAPH_DEFI_FINANCIALS_DATA = 'graph:defi-financials-data',
  GRAPH_STAKE_NFT_INFOS = 'graph:stake-nft-infos',
  GRAPH_FINANCIALS_SUPPLYING_DATA = 'graph:financials-supplying-data',
  GRAPH_FINANCIALS_BORROWING_DATA = 'graph:financials-borrowing-data',
  GRAPH_VENO_FINANCIALS_SUPPLYING_DATA = 'graph:veno-financials-supplying-data',
  GRAPH_VENO_FINANCIALS_BORROWING_DATA = 'graph:veno-financials-borrowing-data',
  GRAPH_DEFI_FINANCIALS_SUPPLYING_DATA = 'graph:defi-financials-supplying-data',
  GRAPH_DEFI_FINANCIALS_BORROWING_DATA = 'graph:defi-financials-borrowing-data',
  GRAPH_TONIC_CIRCULATING_SUPPLY = 'graph:tonic-circulating-supply',
  GRAPH_REVENUE_PAID_STAKER = 'graph:revenue-paid-staker',
  TECTONIC_BORROW_AMOUNT = 'tectonic:borrow-amount',
  TECTONIC_BORROW_APY = 'tectonic:borrow-apy',
  TECTONIC_TONIC_DAILY_DISTRIBUTION_RATE_FOR_BORROW = 'tectonic:tonic-daily-distribution-rate-for-borrow',
  TECTONIC_NON_TONIC_DAILY_DISTRIBUTION_RATES_FOR_BORROW = 'tectonic:non-tonic-daily-distribution-rates-for-borrow',
  TECTONIC_USER_BORROW_AMOUNTS = 'tectonic:user-borrow-amounts',
  TECTONIC_COLLATERAL_FACTORS = 'tectonic-supply:collateral-factors',
  TECTONIC_CONVERSION_BOUNTY_DATA = 'tectonic-supply:conversion-bounty',
  TECTONIC_IS_COLLATERAL = 'tectonic-supply:is-collateral',
  TECTONIC_STAKING_EXCHANGE_RATE = 'tectonic-staking:exchange-rate',
  TECTONIC_TONIC_DAILY_DISTRIBUTION_RATE_FOR_SUPPLY = 'tectonic:tonic-daily-distribution-rate-for-supply',
  TECTONIC_NON_TONIC_DAILY_DISTRIBUTION_RATES_FOR_SUPPLY = 'tectonic:non-tonic-daily-distribution-rates-for-supply',
  TECTONIC_SUPPLY_AMOUNT = 'tectonic:supply-amount',
  TECTONIC_SUPPLY_APY = 'tectonic:supply-apy',
  TECTONIC_USER_SUPPLY_AMOUNTS = 'tectonic:user-supply-amounts',
  TECTONIC_SUPPLY_EXCHANGE_RATE = 'tectonic-supply:exchange-rate',
  TECTONIC_TTOKEN_AMOUNTS = 'tectonic-supply:ttoken-amounts',
  TECTONIC_VAULT_PENDING_TONIC = 'tectonic-vault:pending-tonic',
  TECTONIC_VAULT_POOLS = 'tectonic-vault:pools',
  TECTONIC_VAULT_DEPOSITS = 'tectonic-vault:deposits',
  TECTONIC_PARTNER_TOKENS = 'tectonic:partner-tokens',
  TECTONIC_ESTIMATE_TONIC_IN_BATCH = 'tectonic:estimate-tonic-in-batch',
  TECTONIC_PARTNER_TOKEN_LIST = 'tectonic:partner-token-list',
  TECTONIC_ERC721_BALANCE_OF = 'tectonic-erc721:balance-of',
  TECTONIC_ERC721_TOKENS_OF_OWNER_BY_INDICES = 'tectonic-erc721:tokens-of-owner-by-indices',
  TECTONIC_ERC721_TOKEN_URI = 'tectonic-erc721:token-uri',
  TECTONIC_VAULT_STAKE_NFTS = 'tectonic-vault:stake-nfts',
  TECTONIC_VAULT_UNSTAKE_NFTS = 'tectonic-vault:unstake-nfts',
  TECTONIC_VAULT_REPLACE_NFTS = 'tectonic-vault:replace-nfts',
  TECTONIC_VAULT_GET_USER_STAKED_COLLECTIONS = 'tectonic-vault:get-user-staked-collections',
  TECTONIC_VAULT_GET_ENABLED_NFT_COLLECTIONS = 'tectonic-vault:get-enabled-nft-collections',
  TECTONIC_VAULT_GET_USER_STAKED_NFTS_BY_COLLECTION = 'tectonic-vault:get-user-staked-nfts-by-collection',
  TECTONIC_VAULT_GET_STAKED_NFT_TOKEN_IDS = 'tectonic-vault:get-token-ids',
  TECTONIC_VAULT_GET_USER_NFT_BOOST_MULTIPLIER = 'tectonic-vault:get-user-nft-boost-multiplier',
  TECTONIC_VAULT_GET_ESTIMATED_USER_NFT_BOOST_MULTIPLIER = 'tectonic-vault:get-estimated-user-nft-boost-multiplier',
  TECTONIC_VAULT_GET_MAX_ESTIMATED_USER_NFT_BOOST_MULTIPLIER = 'tectonic-vault:get-max-estimated-user-nft-boost-multiplier',
  TECTONIC_VAULT_GET_NFT_MULTIPLIER = 'tectonic-vault:get-nft-multiplier',
  TECTONIC_ASSET_PRICE_IN_USD = 'tectonic:asset-price-in-usd',
  TECTONIC_PARTNER_TOKEN_PRICE_IN_USD = 'tectonic:partner-token-price-in-usd',
  RISK_DAO_BAD_DEBT = 'riskdao:bad-debt',
  VVS_SWAP_TRADE_DATA = 'vvs:swap-trade-data',
  WOWMAX_SWAP_TRADE_DATA = 'vvs:wowmax-trade-data',
  BOOST_MARKET = 'boost:market',
  REMOVE_BOOST_MARKET = 'boost:remove-market',
  REPLACE_BOOST_MARKET = 'boost:replace-market',
  CALCULATE_BOOST_MULTIPLIER = 'boost:calculate-multiplier',
  GET_USER_BOOST_INFO = 'boost:get-user-info',
  GET_USER_BOOST_COLLECTIONS = 'boost:get-user-collections',
  GET_UNCLAIM_TONIC_REWARDS = 'boost:get-unclaim-tonic-rewards',
  GET_TONIC_MARKETSTATES = 'boost:get-tonic-marketstates',
  GET_XTONIC_BOOST_DAILY_DISTRIBUTE_RATE = 'boost:get-xtonic-boost-daily-distribute-rate',
  GET_TTOKEN_SUPPLY_AMOUNT_USER = 'tectonic:ttoken-supply-amount-user',
  GET_TTOKEN_BORROW_AMOUNT_USER = 'tectonic:ttoken-borrow-amount-user',
  GET_TRANSACTION_HISTORIES_USER = 'tectonic:transaction-histories-user',
}
